<template>
  <div class="pl-2">
    <label
      class="mr-2"
      :class="{ 'd-block': vertically }"
      :key="index"
      v-for="(item, index) in options"
    >
      <input
        :id="`${name}-option-${index}-${item.id || item.code || item}`"
        :checked="value === item.id || value === item.code || value === item"
        type="radio"
        :value="item.id || item"
        :name="name"
        :disabled="disabled || false"
        @input="$emit('change', name, item.id || item)"
      />
      <span class="ml-2">{{ item.label }}</span>
      <span v-if="item.html_content" v-html="item.html_content" class="ml-1"></span>
      <i
        class="fa-solid fa-circle-info mx-1"
        v-if="showInfo && item.info"
        v-c-tooltip="{
          content: item.info,
        }"
      ></i>
    </label>
    <small class="d-block has-error" v-if="error">{{ error }}</small>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: "radio-group",
    },
    value: {
      type: [Object, String, Number, Boolean],
      default: () => "",
    },
    options: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showInfo: {
      type: Boolean,
      default: false,
    },
    vertically: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.has-error {
  color: $red;
}
</style>